<template>
  <div>
    <content-card title="Detalles" :class="{ 'quick-start-selected': $store.state.quickStart.step === 7 }">
      <quick-start-tip
        title="Detalles de la Orden"
        content="Puedes asignar a la orden"
        :options="['Prioridad', 'Fechas de compromiso', 'Técnico responsable']"
        placement="left"
        :bg-purple="false"
        :value="$store.state.quickStart.step === 7"
        @onNextStep="handleNextStep">
        <div class="flex flex-col">
          <div class="my-2">
            <div class="mb-1">
              <span class="font-bold">Prioridad</span>
            </div>
            <el-select
              class="w-full"
              v-model="form.priority"
              @change="changePriority"
              filterable
              placeholder="Seleccionar">
              <el-option
                v-for="priority in priorities"
                :key="priority.value"
                :label="priority.label"
                :value="priority.value">
                <div class="flex flex-row items-center">
                <span class="ml-2">
                  {{ priority.label }}
                </span>
                </div>
              </el-option>
            </el-select>
          </div>
          <div class="my-2">
            <div class="mb-1">
              <span class="font-bold">Fecha compromiso</span>
            </div>
            <el-date-picker
              class="w-full"
              v-model="form.dueDate"
              @change="changeDueDate"
              type="date"
              :format="$store.state.business.dateFormat"
              placeholder="Seleccionar...">
            </el-date-picker>
          </div>
          <div class="my-2">
            <div class="mb-1">
              <span class="font-bold">Asignar responsable</span>
            </div>
            <el-select
              clearable
              class="w-full py-2"
              v-model="form.assignedToAccountId"
              @change="changeUser"
              placeholder="Seleccionar...">
              <el-option
                v-for="user in users"
                :key="user.value"
                :label="user.label"
                :value="user.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </quick-start-tip>
    </content-card>
  </div>
</template>
<script>
import ContentCard from '@/components/molecules/ContentCard.vue';
import { PriorityOptions } from '@/constants';
import AdminService from '@/services/AdminService';
import { state, mutations } from "../store";
import { QUICK_START_STORE } from '@/store/modules/quick-start/mutation-types';

export default {
  name: 'DetailsBlock',
  components: {
    ContentCard
  },
  data() {
    return {
      form: {
        priority: null,
        dueDate: null,
        assignedToAccountId: null,
      },
      users: [],
      priorities: PriorityOptions,
    };
  },
  mounted() {
    this.loadUsers();
    setTimeout(() => {
      this.form.priority = state.order.priority;
      this.form.dueDate = state.order.dueDate;
      this.form.assignedToAccountId = state.order.assignedToAccountId;
    }, 1000);
  },
  methods: {
    changePriority(priority) {
      mutations.changePriority(priority);
    },
    changeDueDate(dueDate) {
      mutations.changeDueDate(dueDate);
    },
    changeUser(user) {
      mutations.changeUser(user);
    },
    async loadUsers() {
      try {
        const users = await AdminService.UsersAndPermissions.getUsers();
        this.users = users.map((u) => ({ value: u.id, label: u.fullname }));
      } catch (e) {
        console.log('error', e);
      }
    },
    handleNextStep() {
      const step = this.$store.state.quickStart.step;
      this.$store.dispatch(QUICK_START_STORE, { step: step + 1 });
    }
  }
}
</script>
